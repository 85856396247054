<template>
  <layout-login>
    <h1 v-html="title" :class="{'text-center': isLoading}"></h1>
    <p v-html="subTitle" :class="{'text-center': isLoading}"></p>

    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
      @closed="modalClose"
      @opened="logoutUser"
    >
      <div @click="showModal = false">
        <i role="button" class="modal__close fas fa-x"></i>
      </div>
      <span class="modal__title">Något gick fel!</span>
      <div class="modal__content">
        <p>{{userErrorMessage}}</p>
      </div>
    </vue-final-modal>

    <form
      class="mt-5"
      action=""
      autocomplete="chrome-off"
      list="autocompleteOff"
      v-show="!isLoading"
    >
      <div class="mb-3">
        <input
          type="email"
          id="email"
          class="form-control"
          placeholder="E-post"
          v-model="email"
          autocomplete="chrome-off"
          list="autocompleteOff"
        />
      </div>
      <div class="mb-3">
        <input
          type="password"
          id="password"
          class="form-control"
          placeholder="Lösenord"
          v-model="password"
          autocomplete="chrome-off"
          list="autocompleteOff"
          v-if="!forgotPassword"
        />
      </div>
      <div
        class="login-actions mt-5 d-flex align-items-center justify-content-between"
      >
        <a href="#" @click="forgotPassword = !forgotPassword" v-if="!forgotPassword">
          Glömt lösenord ?
        </a>
        <a href="#" @click="forgotPassword = !forgotPassword" v-if="forgotPassword">
          Tillbaka
        </a>
        <button class="btn btn-primary" @click="login($event)" v-if="!forgotPassword">Fortsätt</button>
        <button class="btn btn-primary btn-small" v-on:click="newPassword($event)" v-if="forgotPassword">Begära nytt lösenord</button>
      </div>
    </form>
  </layout-login>
</template>

<script>
import LayoutLogin from '@/views/Layouts/Login';
import BojAPI from '@/api/boj';
import Cookies from '@/service/cookie';

export default {
  components: {
    LayoutLogin,
  },
  data() {
    return {
      forgotPassword: false,
      isLoading: false,
      title: "Välkommen till Minerva",
      subTitle: "Logga in för att fortsätta",
      email: "",
      password: "",
      showModal: false,
      userErrorMessage: ""
    };
  },
  mounted() {
    document.getElementById('app').classList.add('login'); // Add class to body

    /**
     *
     * Todo: Fix notifications
     *
    */
    if (Cookies.get('refreshTokenActive')) {

      this.$toast.warning('Du har loggats ut ur Minerva automatiskt. Det görs då och då av säkerhetsskäl. Du bara loggar in igen som vanligt.', {
        position: 'top-center',
      });

      Cookies.remove('refreshTokenActive');
    }

  },
  created: function () {
    document.getElementById('app').classList.add('login');
  },
  destroyed: function () {
    document.getElementById('app').classList.remove('login');
  },
  methods: {
    modalClose() {
      this.title = "Välkommen till brottsofferjouren";
      this.subTitle = "Logga in för att fortsätta";
      this.email = "";
      this.password = "";
      this.isLoading = false;
    },
    logoutUser() {
      this.$store.dispatch('logout')
      .then(() => {
        console.log('Is now loggedout')
      })
    },
    newPassword: function (event) {
      if (event) event.preventDefault();
      BojAPI.sendResetPasswordMail(this.email).then(() => {
        this.$toast.success('Förfrågan om nytt lösenord har skickats. Kolla din e-post.', {
          position: 'top-center',
        });

        this.forgotPassword = false;
      });
    },
    login($event) {
      if ($event) $event.preventDefault();
      this.title = '</i> <i class="fas fa-circle-notch fa-spin"></i> Vänligen vänta..';
      this.isLoading = true;
      this.subTitle = "Testar åtkomstinformation..";
      let username = this.email;
      let password = this.password;

      this.$store
        .dispatch("login", { username, password })
        .then(() => {
          this.subtitle = "Loggar in...";
          if (this.$store.getters.otpOptions.otp_channel && !Cookies.get('otp')) {
            this.$router.push({ name: 'otp-verify' });
            return;

          }

          this.$router.checkLoginData()
          .then(() => {
            if (this.$store.getters.user.member === null) {
              this.userErrorMessage = "Användaren har ingen koppling till en medlem.";
              this.showModal = true
              return
            }

            if (!this.$store.getters.permissions.includes('court-page-active')) {
              this.userErrorMessage = "Användaren har ingen behörighet.";
              this.showModal = true
              return
            }

            this.$router.push(this.$route.query.redirect || '/main-menu');
          })
          .catch((error) => {
            console.log(error);
            this.title = 'Något gick fel!';
            this.subTitle = 'Vänta några minuter och pröva igen!';
            this.isLoading = false;
          });
        })
        .catch(error => {
          console.log(error);

          if (error.response && error.response.status == 429) {
            this.title = "Kontot spärrat";
            this.subTitle =
              "Du har försökt logga in för många gånger med felaktiga uppgifter. Kom tillbaka om en stund och försök igen!";
            this.isLoading = false;

            return;
          }

          this.title = "Pröva igen!";
          this.subTitle = "Din e-post eller lösenord är fel!";
          this.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 3rem;
  padding: 2rem 1rem;
  /* border: 1px solid #e2e8f0; */
  border-radius: 0.25rem;
  background: #406c5d;

}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 500;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 25px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
</style>
