<template>
    <layout-login>
        <h1 class="" v-html="title"></h1>
        <p v-html="subtitle" v-if="!isLoading && otpOptions.otp_channel"></p>
        <p class="" v-if="!isLoading && otpOptions.otp_channel">
            <span v-html="codeInfo"> </span>

        </p>

        <router-link :to="{ name: 'logout' }" v-if="!otpOptions.otp_channel && isLoading == false">Tillbaka</router-link>

        <form v-if="otpOptions.otp_channel" class="mt-5" action="" autocomplete="chrome-off" list="autocompleteOff"
            v-show="!isLoading">



            <section class="d-flex">
                <SimpleOtpInput class="otp-with-effect" inputClasses="input-with-effect" @complete="handleComplete"
                    :pasteDelayMs="192" withWebOtp v-model="otp">
                    <template v-slot:extra>
                        <span class="focus-border">
                            <i></i>
                        </span>
                    </template>
                </SimpleOtpInput>
            </section>
            <div class="login-actions">
                <p class="" v-if="!isLoading">
                    <br>
                    <a class=" " href="#" v-on:click="newOtpClicked($event)">Skicka kod igen</a>
                </p>

                <div class=" mt-5 d-flex align-items-center justify-content-between">
                    <router-link style="font-weight: 500; font-size: 1.1rem;" to="/otp-edit">Sänd koden till annan e-post
                        eller
                        mobil</router-link>

                    <button class="btn btn-primary" v-on:click="verifyOtp($event)">Fortsätt</button>


                </div>
            </div>
        </form>
    </layout-login>
</template>
<script>
import LayoutLogin from '@/views/Layouts/Login';
import BojAPI from '@/api/boj';
import store from '@/store';
import Cookies from '@/service/cookie';
import SimpleOtpInput from "vue-simple-otp-input";
require('vue-simple-otp-input/dist/vue-simple-otp-input.css');

export default {
    components: {
        LayoutLogin,
        SimpleOtpInput,
    },
    data() {
        return {
            state: '',
            isLoading: false,
            username: '',
            password: '',
            title: 'Verifikationskontroll',
            otp: '',
            otpOptions: [],
        };
    },
    created: function () {
        document.getElementById('app').classList.add('login');
    },
    destroyed: function () {
        document.getElementById('app').classList.remove('login');
    },
    async mounted() {
        document.getElementById('app').classList.add('login'); // Add class to body
        this.isLoading = true;
        this.title = '<i class="fas fa-circle-notch fa-spin"></i> Laddar..';
        this.$store.dispatch('getOtpOptions').then(() => {
            this.title = 'Verifikationskontroll';
            this.isLoading = false;
            this.otpOptions = this.$store.getters.otpOptions;
            this.newOtp();
        })
    },
    computed: {
        subtitle() {
            if (this.otpOptions.otp_channel == 'email') {
                return '<span class="text-light">Ett mail har skickats till ' + this.otpOptions.otp_email + ' med en verifieringskod.</span>';
            } else if (this.otpOptions.otp_channel == 'sms') {
                return '<span class="text-light">Ett SMS har skickats till ' + this.otpOptions.otp_sms + ' med en verifieringskod.</span>';
            } else {
                return '';
            }
        },
        codeInfo() {
            return '<span class="text-light">Ange koden i rutorna nedan. Koden är giltig i 10 minuter.</span> ';
        }
    },
    methods: {
        handleComplete(value) {
            console.log('OTP completed!! ', value);

            this.verifyOtp();
        },
        verifyOtp(event) {
            if (event) event.preventDefault();
            if (this.isLoading) {
                return;
            }

            if (this.otp.replace(/\s/g, '').length != 6) {
                return;
            }

            this.isLoading = true;

            this.title = '<i class="fas fa-circle-notch fa-spin"></i> Verifierar..';
            BojAPI.verifyOtp(this.otp).then((response) => {

                Cookies.set('otp', response.data.otp, { expires: 7 });
                this.title = '<i class="fas fa-circle-notch fa-spin"></i> Vänligen vänta..';


                this.$router.checkLoginData()
                    .then(() => {
                        console.log('finish checklogindata!!!!!!!!!!');

                        this.$router.push(this.$route.query.redirect || '/main-menu');
                      
                    })
                    .catch((error) => {
                        this.title = 'Något gick fel!';
                        this.isLoading = false;
                        console.log(error);
                    });


            }).catch((error) => {
                this.isLoading = false;
                this.title = 'Fel kod';
                this.otp = '';
                this.$toast.error('Fel kod', {
                    position: 'top-center',
                });
            });

        },
        async newOtpClicked(event) {
            if (event) event.preventDefault();

            await this.newOtp()

        },
        async newOtp() {
            return BojAPI.newOtp().then((response) => {
                console.log(response);
                this.$toast.success('Förfrågan om nytt engångslösenord har skickats.', {
                    position: 'top-center',
                });
            }).catch((error) => {
                this.$toast.error('Du måste vänta lite innan du kan skicka nytt engångslösenord.', {
                    position: 'top-center',
                });
            });
        }
    },
};
</script>

<style>
.otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;


}

.input-with-effect~.focus-border:after,
.input-with-effect~.focus-border:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 2px;
    background-color: green;
    transition: .1s;
    transition-delay: .2s
}

.input-with-effect~.focus-border:after {
    top: auto;
    bottom: 0;
    right: auto;
    left: 0;
    transition-delay: .6s
}

.input-with-effect~.focus-border i:after,
.input-with-effect~.focus-border i:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 0;
    background-color: green;
    transition: .1s
}

.input-with-effect~.focus-border i:after {
    left: auto;
    right: 0;
    top: auto;
    bottom: 0;
    transition-delay: .2s
}

.input-with-effect.active~.focus-border:after,
.input-with-effect.active~.focus-border:before,
.input-with-effect:focus~.focus-border:after,
.input-with-effect:focus~.focus-border:before {
    width: 100%;
    transition: .1s;
    transition-delay: .3s
}

.input-with-effect.active~.focus-border:after,
.input-with-effect:focus~.focus-border:after {
    transition-delay: .1s
}

.input-with-effect.active~.focus-border i:after,
.input-with-effect.active~.focus-border i:before,
.input-with-effect:focus~.focus-border i:after,
.input-with-effect:focus~.focus-border i:before {
    height: 100%;
    transition: .1s
}

.input-with-effect.active~.focus-border i:after,
.input-with-effect:focus .focus-border i:after {
    transition-delay: .2s
}
</style>