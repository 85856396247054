<template>
  <div class="login-wrapper container">

    <div class="row">
      <div class="col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
        <img class="logo" :src="logoUrl" alt="">
      </div>

      <div class="login-separator p-0 d-none d-md-block"></div>

      <div class="col-12 col-md-6 d-flex">
        <div class="login-content px-2 align-self-center">
          <slot></slot>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      logoUrl: process.env.VUE_APP_API_LINK + 'logo?tag=domstol&' + Math.floor(Math.random() * 99999)
    };
  },
};
</script>
