<template>
  <layout-login>
    <h1 v-html="title"></h1>
    <p class="mb-2">Återställ lösenord</p>
    <!--begin::Form-->
    <form class="reset-password" action="" autocomplete="chrome-off" list="autocompleteOff" v-show="!isLoading">
      <div class="form-group">
        <input
            class="form-control"
            type="text"
            placeholder="E-post"
            v-model.trim="$v.username.$model"
            name="username"
            autocomplete="chrome-off"
            list="autocompleteOff"
        />
      </div>
      <ul class="validation-list">
        <li :class="{'error':!$v.username.email || !$v.username.required}">Du måste ange en korrekt e-postadress</li>
      </ul>
      <div class="form-group">
        <input
            class="form-control"
            :type="passwordFieldType"
            autocomplete="new-password"
            placeholder="Nytt lösenord"
            v-model="$v.password.$model"
            name="password"
        />
        <span @click="switchVisibility" class="fa fa-fw fa-eye field-icon toggle-password" :class="{'fa-eye-slash': passwordIsVisible}"></span>
      </div>
        <ul class="validation-list">
          <li :class="{'error':!$v.password.containsLowercase || !$v.password.minLength}">Lösenordet måste vara minst 10 tecken</li>
          <li :class="{'error':!$v.password.containsNumber}">Lösenordet måste innehålla minst ett nummer</li>
          <li :class="{'error':!$v.password.containsSpecial}">Lösenordet måste innehålla minst ett specialtecken</li>
          <li :class="{'error':!$v.password.containsUppercase}">Lösenordet måste innehålla minst en stor bokstav</li>
        </ul>
      <div class="form-group">
        <input
            autocomplete="repeat-new-password"
            class="form-control"
            :type="passwordFieldType"
            placeholder="Upprepa lösenord"
            v-model="$v.repeatPassword.$model"
            name="password"
        />
      </div>
      <ul class="validation-list" v-if="$v.repeatPassword.$dirty">
        <li :class="{'error':!$v.repeatPassword.sameAsPassword || $v.repeatPassword.$invalid}">Lösenordet stämmer inte överens</li>
      </ul>
      <div class="login-actions mt-5 d-flex align-items-center justify-content-between">
        <a href="/">
          Tillbaka
        </a>
        <button
            class="btn btn-primary"
            :disabled="$v.$invalid"
            v-on:click="setNewPassword($event)"
            v-if="!forgotPassword"
        >
          Fortsätt
        </button>
      </div>
    </form>
    <!--end::Form-->
  </layout-login>
</template>
<script>
import LayoutLogin from '@/views/Layouts/Login';
import BojAPI from '@/api/boj';
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'

export default {
  components: {
    LayoutLogin,
  },
  props: {
    token: String,
  },
  data() {
    return {
      forgotPassword: false,
      isLoading: false,
      username: '',
      password: '',
      repeatPassword: '',
      passwordFieldType: 'password',
      passwordIsVisible: false,
      title: 'Välkommen till <span>Brottsofferjouren</span>',
      subtitle: 'Logga in för att fortsätta',
    };
  },
  mounted() {
    document.getElementById('app').classList.add('login'); // Add class to body
  },
  created: function () {
    document.getElementById('app').classList.add('login');
  },
  destroyed: function () {
    document.getElementById('app').classList.remove('login');
  },
  computed: {
    loadingStyles() {
      return this.isLoading ? 'display: flex; justify-content: center;' : ''
    }
  },
  validations: {
    username: {
      required,
      email,
    },
     password: {
      required,
      minLength: minLength(10),
      containsUppercase: (value) => {
        return /[A-Z]/.test(value)
      },
      containsLowercase: (value) => {
        return /[a-z]/.test(value)
      },
      containsNumber: (value) => {
        return /[0-9]/.test(value)
      },
      containsSpecial: (value) => {
        return /[#?!@$%^&*-]/.test(value)
      }
    },
    repeatPassword: {
      sameAsPassword: sameAs('password')
    }
  },

  methods: {
    switchVisibility: function() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.passwordIsVisible = !this.passwordIsVisible;
    },
    setNewPassword: function () {
      if (event) event.preventDefault();
      BojAPI.sendResetPasswordRequest(this.username, this.password, this.token).then(() => {
        alert('Ditt lösenord är ändrat och du kan nu logga in.');
        this.$router.push({path: '/login'});
      });
    },
  },
};
</script>
